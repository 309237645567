// this mixin will just upload the file on the storage server.
export default {
  methods: {
    toBase64(url) {
      return fetch(url)
        .then(response => response.blob())
        .then(
          blob =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onerror = reject;
              reader.onloadend = () => resolve(reader.result);
            })
        );
    },
    //Upload a photo to the local
    async fileUpload(e, objectToUpdate) {
      //Getting the files from the vue upload
      var files = e.target.files;
      // Getting the first file
      var getFile = files[0]; // getFile is now a Blob
      console.log(getFile.size / 1024 / 1024 + 'MB Photo uploading...');
      if (getFile.size / 1024 / 1024 > 5) {
        // 1MB
        alert('This file is too large, must be less than 5MB');
        return;
      }
      //Creating the Url
      var url = URL.createObjectURL(getFile);

      console.log(url);

      var self = this;

      const { dataUrl, height, width } = await this.getBase64WithWidthAndHeight(
        url
      );
      self[objectToUpdate].brandingImageBase64 = dataUrl;
      self[objectToUpdate].brandingImageHeight = height;
      self[objectToUpdate].brandingImageWidth = width;
      // this.imgUrls.push(url);
      this.toDataURL(url, function(dataUrl) {
        console.log({ dataUrl });
        self[objectToUpdate].brandingImageBase64 = dataUrl;
        self.$forceUpdate();
      });
    },

    getBase64WithWidthAndHeight(url) {
      return new Promise((resolve, reject) => {
        this.toBase64(url).then(dataUrl => {
          // self[objectToUpdate].brandingImageBase64 = dataUrl;
          var image = new Image();
          image.onload = () => {
            resolve({ width: image.width, height: image.height, dataUrl });
          };
          // console.log(dataUrl);
          image.src = dataUrl;
          // image.src = self[objectToUpdate].brandingImageBase64;
        });
      });
    },

    removeBrandingImage(objectToUpdate) {
      this[objectToUpdate].brandingImageBase64 = null;
      this[objectToUpdate].brandingImageHeight = null;
      this[objectToUpdate].brandingImageWidth = null;
      this.$forceUpdate()
    },

    //Converts an uploaded photo to base64
    toDataURL: function(src, callback) {
      var xhttp = new XMLHttpRequest();

      xhttp.onload = function() {
        var fileReader = new FileReader();
        fileReader.onloadend = function() {
          callback(fileReader.result);
        };
        fileReader.readAsDataURL(xhttp.response);
      };

      xhttp.responseType = 'blob';
      xhttp.open('GET', src, true);
      xhttp.send();
    },

    //Converts binary (DATA URI) from the database to a more uniform URL
    dataURItoBlob: function(dataURI) {
      if (!dataURI) return '';
      var array, binary, i;

      //Gets the binary from the Data URI
      binary = atob(dataURI.split(',')[1]);
      array = [];
      i = 0;
      while (i < binary.length) {
        array.push(binary.charCodeAt(i));
        i++;
      }

      //Takes a blob and converts it to a url:
      return URL.createObjectURL(
        new Blob([new Uint8Array(array)], {
          type: 'image/jpeg',
        })
      );
    },
  },
};
