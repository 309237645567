<!-- @format -->

<template>
  <div class="custom-modal-overlay" v-if="isOpen">
    <div class="custom-modal">
      <div class="custom-modal-content">
        <!-- <div class="custom-modal-header">
          <span class="custom-modal-close" @click="closeModal"
            >&times;</span
          >
          <h2>{{ title }}</h2>
        </div> -->
        <h2 class="h2 pb-2 pt-3 px-3 text-center">
          {{
            "Is this evaluation for training/testing purposes?"
              | translate
          }}
        </h2>
        <div class="radio-btn m-4">
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input"
                type="radio"
                name="is_for_testing"
                :value="true"
                data-vv-as="Is For Testing"
                v-validate="'required'"
                :checked="evaluation.isForTesting === true"
                @input="updateIsForTesting(true)"
              />
              {{ "Yes" | translate }}
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input"
                type="radio"
                name="is_for_testing"
                :value="false"
                data-vv-as="Is For Testing"
                v-validate="'required'"
                :checked="evaluation.isForTesting === false"
                @input="updateIsForTesting(false)"
              />
              {{ "No" | translate }}
            </label>
          </div>
          <span
            v-if="errors.has('is_for_testing')"
            class="text-danger d-block"
          >
            <small>{{
              "The 'Is For Testing' field is required." | translate
            }}</small>
          </span>
        </div>
        <!-- <div class="text-center m-3">
          <button class="btn-cancel-modal" @click="closeModal">
            {{ "Cancel" | translate }}
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_EVALUATION_BY_ID_GETTER,
  GET_UPDATE_EVALUATION,
} from "@/store/types";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Modal Title",
    },
  },
  data() {
    return {
      evaluationId: this.$route.params.evaluationId,
      evaluation: {},
    };
  },
  computed: {
    // ...mapState({
    //   evaluation: (state) => state.evaluations.evaluation,
    // }),
    ...mapGetters("evaluations", [GET_EVALUATION_BY_ID_GETTER]),
  },
  mounted() {
    this.evaluation = this[GET_EVALUATION_BY_ID_GETTER](
      this.evaluationId
    );
  },
  methods: {
    ...mapActions("evaluations", [GET_UPDATE_EVALUATION]),

    closeModal() {
      this.$emit("update:isOpen", false); // Emit event to close the modal
    },
      async updateIsForTesting(value) {
      this.evaluation.isForTesting = value;
       await this[GET_UPDATE_EVALUATION](this.evaluation);
        this.closeModal();
    },
  },
  // watch: {
  //   "evaluation.isForTesting": {
  //     handler() {
  //       this[GET_UPDATE_EVALUATION](this.evaluation);
  //     },
  //     deep: true,
  //   },
  // },
};
</script>

<style scoped>
.custom-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.radio-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-modal {
  border: 10px solid #ffffff;
  padding: 10px 20px 0px 20px;
  background: #e2e3e4;
  width: 80%;
  max-width: 600px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.custom-modal-header {
  padding: 10px;
  /* border-bottom: 1px solid #ccc; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal-close {
  cursor: pointer;
  font-size: 24px;
}

.custom-modal-body {
  padding: 30px;
}
</style>
