<template>
  <div>
    <div>
      <p>{{ content.title | translate }}</p>
      <p class="verification-p">
        {{
          'When a user signs up an email address and password, you can send them a confirmation email to verify there registered email address.'
            | translate
        }}
      </p>
      <hr />
    </div>
    <form @submit.prevent="handleEmailSubmit">
      <div>
        <p class="verification-p-h">{{ 'Sender name' | translate }}</p>

        <input
          class="verification-input p-2"
          v-model="content.senderName"
          type="text"
          placeholder="Zinpro FirstStep"
        />
      </div>
      <div class="mt-4">
        <p class="verification-p-h">{{ 'Reply to' | translate }}</p>
        <input
          class="verification-input-long p-2"
          v-model="content.replyTo"
          type="text"
          name="email"
          data-vv-as="Email"
          v-validate="'email'"
        />
        <span v-show="errors.has('email')" class="text-danger">
          <small>{{ 'this is an invalid email' | translate }}</small>
        </span>
      </div>
      <div class="mt-4">
        <p class="verification-p-h">{{ 'Subject' | translate }}</p>
        <input
          class="verification-input-long p-2"
          v-model="content.subject"
          placeholder="Enter Subject"
          type="text"
        />
      </div>
      <div class="mt-4">
        <p class="verification-p-h">{{ 'Message' | translate }}</p>
        <textarea
          class="verification-textarea-long p-2"
          v-model="content.content"
          placeholder="Message"
          name=""
          id=""
          cols="30"
          rows="10"
        ></textarea>
      </div>
      <!-- <div class="mt-4">
        <p class="verification-p-h">
          {{ 'Action URL(%LINK%value)' | translate }}
        </p>
        <input
          class="verification-textarea-long p-2"
          v-model="Action"
          placeholder="www.example.com/reset-password"
          type="text"
        />
      </div> -->
      <div class="btn-container">
        <!-- <button class="btn btn-secondary btn-full--sm mt-2 mt-md-4">
          {{ 'Cancel' | translate }}
        </button> -->
        <button
          :disabled="loader"
          type="submit"
          class="btn btn-primary btn-full--sm float-right mt-4"
          @click="verificationDataSave()"
        >
          {{ 'Save' | translate }}
          <span v-if="loader" class="loader ml-1"></span>
        </button>
      </div>
    </form>

    <div
      v-show="confirmMessage"
      class="alert alert-primary mt-4 mb-0"
      role="alert"
    >
      {{ 'Template has been updated!' | translate }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { GET_UPDATE_EMAIL_TEMPLATE } from '../../../../store/types';
export default {
  name: 'EmailAddressVerification',
  props: {
    content: {
      type: Object,
      required: true,
    },
    activeTabIndex: {
      type: Number,
      require: true,
    },
  },
  inject: ['$validator'],
  data() {
    return {
      loader: false,
      SenderName: '',
      emailTemplate: '',
      ReplyTo: '',
      Subject: '',
      Message: '',
      Action: '',
      confirmMessage: false,
    };
  },
  computed: {
    ...mapState({
      emailTemplates: state => state.emailTemplate.emailTemplates,
    }),
  },
  watch: {
  
    content:{
      handler(){
      this.$validator.reset();
        console.log('go to');
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('emailTemplate', [GET_UPDATE_EMAIL_TEMPLATE]),
    handleEmailSubmit() {
      this.$validator.validateAll().then(async allFildsAreValid => {
        console.log({ allFildsAreValid });
        if (!allFildsAreValid) return;
        this.loader = true;
        await this[GET_UPDATE_EMAIL_TEMPLATE](this.content);
        this.loader = false;
        this.confirmMessage = true;
        setTimeout(() => (this.confirmMessage = false), 5000);
      });
    },
    verificationDataSave() {
      console.log(
        this.SenderName,
        this.ReplyTo,
        this.Subject,
        this.Message,
        this.Action
      );
    },
  },
  mounted() {
    this.emailTemplate = this.emailTemplates.find(
      emailTemplate => emailTemplate._id === this.$route.query.template_id
    );
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  justify-content: end;
  margin: 0;
}
</style>
