<template>
  <div>
    <div class="main-container main-container--margin-bt container">
      <div class="card mb-0">
        <div class="row">
          <div class="col-md-12">
            <h1 class="h1 mb-3 mb-md-1">{{ 'Invite Customer' | translate }}</h1>
          </div>
          <div
            class="col-md-12 d-flex align-items-center justify-content-lg-end flex-column-reverse flex-md-row"
          >
            <router-link
              v-if="show === false"
              to="/admin/customer-management"
              class="btn btn-secondary btn-full--sm mr-md-4 mt-2 mt-md-0"
              >{{ 'Back' | translate }}</router-link
            >
            <router-link
              v-else
              to="/"
              class="btn btn-secondary btn-full--sm mr-md-4 mt-2 mt-md-0"
              >{{ 'Back' | translate }}</router-link
            >
            <button
              @click="inviteCustomer('top')"
              type="button"
              class="btn btn-primary btn-full--sm"
            >
              {{ 'Send Invite' | translate }}
              <span v-if="loader" class="loader ml-1"></span>
            </button>
          </div>
        </div>

        <div
          v-if="error === true && location === 'top'"
          class="alert alert-danger mt-2 mb-0"
          role="alert"
        >
          {{ displayError() | translate }}
        </div>

        <div
          v-show="success === true && location === 'top'"
          class="alert alert-primary mt-2 mb-0"
          role="alert"
        >
          {{ 'Invitation sent!' | translate }}
        </div>

        <div class="card-body mt-3">
          <general-profile
            v-model="customer"
            :admin="admin"
            :companies="companies"
          ></general-profile>

          <div class="row">
            <div class="col-md-12">
              <div class="card-body__top">
                <h2 class="h2">{{ 'Permissions' | translate }}</h2>
              </div>

              <hr />

              <div class="form-group">
                <label
                  >{{ 'Role' | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  class="form-control"
                  v-model="customer.role"
                  name="role"
                  data-vv-as="Role"
                  v-validate="'required'"
                >
                  <option selected disabled value="null">{{
                    'Select' | translate
                  }}</option>
                  <option value="external">{{
                    'Advanced Customer' | translate
                  }}</option>
                  <option value="data_entry">{{
                    'Customer' | translate
                  }}</option>
                  <option
                    v-if="user.role === 'admin'"
                    value="zinpro_representative"
                    >{{ 'Zinpro Representative' | translate }}</option
                  >
                  <option v-if="user.role === 'admin'" value="admin">{{
                    'Admin' | translate
                  }}</option>
                </select>
                <span v-show="errors.has('role')" class="text-danger">
                  <small>{{ 'The Role field is required.' | translate }}</small>
                </span>
              </div>
            </div>
            <div
              v-if="showFieldIfUserSelectCustomerOrAdvanceCustomer"
              class="col-md-12"
            >
              <div class="card-body__top">
                <h2 class="h2">{{ 'Zinpro Representative' | translate }}</h2>
              </div>
              <hr />
              <div v-if="zinproReps === null">
                <div class="loading loading--sm">
                  <div class="double-bounce1"></div>
                  <div class="double-bounce2"></div>
                </div>
              </div>
              <div v-else-if="zinproReps.length > 0" class="form-group">
                <label>{{ 'Zinpro Representative' | translate }}</label>
                <span class="required">*</span>
                <select
                  class="form-control"
                  v-model="customer.representative"
                  @change="changedRep(customer.representative)"
                  name="representative"
                  data-vv-as="Zinpro Representative"
                  v-validate="'required'"
                >
                  <option selected disabled value="null">{{
                    'Select' | translate
                  }}</option>
                  <option
                    v-for="(rep, index) in zinproReps"
                    :key="index"
                    :value="rep._id"
                  >
                    {{ rep.firstName }} {{ rep.lastName }}
                  </option>
                </select>
                <span v-show="errors.has('representative')" class="text-danger">
                  <small>{{
                    'The Zinpro Representative field is required.' | translate
                  }}</small>
                </span>
              </div>
              <div v-else>
                <p>{{ 'There are no Zinpro Representatives.' | translate }}</p>
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
        <!-- end card body -->

        <div
          v-if="error === true && location === 'bottom'"
          class="alert alert-danger mt-4 mb-0"
          role="alert"
        >
          {{ displayError() | translate }}
        </div>

        <div
          v-if="success === true && location === 'bottom'"
          class="alert alert-primary mt-4 mb-0"
          role="alert"
        >
          {{ 'Invitation sent!' | translate }}
        </div>

        <div class="row d-flex">
          <div class="col-md-12 order-2 order-md-1">
            <router-link
              v-if="show === false"
              to="/admin/customer-management"
              class="btn btn-secondary btn-full--sm mt-2 mt-md-4"
              >{{ 'Back' | translate }}</router-link
            >
            <router-link
              v-else
              to="/"
              class="btn btn-secondary btn-full--sm mt-2 mt-md-4"
              >{{ 'Back' | translate }}</router-link
            >
          </div>
          <div class="col-md-12 order-1 order-md-2">
            <button
              @click="inviteCustomer('bottom')"
              type="button"
              class="btn btn-primary btn-full--sm float-right mt-4"
            >
              {{ 'Send Invite' | translate }}
              <span v-if="loader" class="loader ml-1"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end container -->
  </div>
</template>

<script>
import Vue from 'vue';
import { Validator } from 'vee-validate';

// profile component
import generalProfile from '../../../components/admin/general.vue';
import axios from 'axios';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  GET_COMPANIES_BY_USER_ID,
  GET_INVITE_CUSTOMER,
} from '../../../store/types';
import { ROLES } from '../../../constants';
import customerMixin from './mixin';
import companyMixin from '@/mixins/companyMixin';

export default {
  components: {
    generalProfile,
  },
  props: ['data'],
  mixins: [customerMixin, companyMixin],
  data() {
    return {
      admin: true,
      errorMessage: '',
      show: false,
      customer: {
        address: null,
        brandingImage: '',
        city: null,
        clientId: null,
        company: null,
        companyName: null,
        countryCode: null,
        createdBy: null,
        currency: null,
        evaluations: [],
        firstName: null,
        lastName: null,
        measurements: 'metric',
        phone: null,
        postalCode: '',
        prefix: null,
        representative: null,
        representativeName: null,
        role: null,
        salesforceId: null,
        stateProvince: null,
        status: true,
        title: null,
      },
      currentUser: null,
      // id: this.$route.params.id,
      success: false,
      error: false,
      errorCode: false,
      location: false,
      loader: false,
      selectedRep: null,
      selectedRep: null,
      zinproReps: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    var self = this;
    console.log(from);
    next(self => {
      if (from.path === '/' || from.path === '/home/') {
        self.show = true;
      } else {
        self.show = false;
      }
      console.log('show', self.show);
      next();
    });
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
      users: state => state.users.users,
      misc: state => state.misc,
      companies: state => state.company.companies,
    }),
    // ...mapGetters('company', [GET_COMPANIES_BY_USER_ID]),

    showFieldIfUserSelectCustomerOrAdvanceCustomer() {
      return (
        this.user.role === 'admin' &&
        !['admin', 'zinpro_representative', null].includes(this.customer.role)
      );
    },
  },
  methods: {
    ...mapActions('users', [GET_INVITE_CUSTOMER]),
    displayError() {
      if (this.errorCode === 'auth/email-already-exists') {
        return 'The email address is already in use by another account.';
      } else if (this.errorCode === 'form/required-fields') {
        return 'Please fill out the required fields!';
      } else if (this.errorMessage) {
        return this.errorMessage;
      } else {
        return 'Sorry! Something went wrong.';
      }
    },

    generateErrorMsg({ errors, message }) {
      return message + (errors ? Object.values(errors).join(', ') : '');
    },

    async validateAllFormsFields(location) {
      this.error = false;
      this.success = false;
      this.location = location;
      this.loader = true;

      const allFieldsValid = await this.$validator.validateAll();

      if (!allFieldsValid) {
        this.error = true;
        this.errorCode = 'form/required-fields';
        this.loader = false;
        setTimeout(() => {
          this.errorCode = '';
          this.error = false;
          this.errorMessage = '';
        }, 4000);
      }
      return allFieldsValid;
    },

    async inviteCustomer(location) {
      const isFormReadyToSubmit = await this.validateAllFormsFields(location);
      if (!isFormReadyToSubmit) return;
      try {
        const {
          representativeName,
          representativeId,
        } = this.getRepresentativeNameAndId();

        const customer = {
          ...this.customer,
          measurements: this.customer.measurements || 'metric',
          lang: this.user.lang,
          ...this.getCompanyName('customer'),
          evaluations: [],
          representative: representativeId,
          representativeName: representativeName,
          stateProvince:
            this.customer.countryCode === 'US' ||
            this.customer.countryCode === 'CA'
              ? this.customer.stateProvince
              : null,
        };
        await this[GET_INVITE_CUSTOMER](customer).then(() => {
          this.$router.push({
            path: '/admin/customer-management',
            query: { success_code: 'user-created' },
          });
        });
      } catch (error) {
        console.log('error', error);
        this.error = true;

        this.errorMessage = this.generateErrorMsg(error);
        this.loader = false;
        this.displayError();
        setTimeout(() => {
          this.error = false;
          this.errorCode = '';
          this.errorMessage = '';
        }, 4000);
      }
    },
    changedRep(value) {
      var reps = this.zinproReps;
      reps = reps.filter(user => {
        return user._id === value;
      });
      this.selectedRep = reps[0];
      this.customer.representative = this.selectedRep._id;
      this.customer.representativeName =
        this.selectedRep.firstName + ' ' + this.selectedRep.lastName;
    },
  },
  async created() {
    try {
    setTimeout(() => {
      // const users = await this[GET_USERS]();
      this.zinproReps = this.users.filter(({ role }) => {
        return role === ROLES.zinpro_representative;
      });

      // Sort zinproReps by last name
      this.zinproReps.sort((a, b) => {
        return a.lastName.localeCompare(b.lastName);
      });
    }, 1000);
  } catch (error) {
      console.error(error);
    }
  },

  // address: this.customer.address,
  // brandingImage: this.customer.brandingImage,
  // city: this.customer.city,
  // clientId: this.customer.clientId,
  // status: this.customer.status,
  // title: this.customer.title,
  // company: this.customer.company,
  // countryCode: this.customer.countryCode,
  // createdBy: this.customer.createdBy,
  // currency: this.customer.currency,
  // email: this.customer.email,
  // firstName: this.customer.firstName,
  // lastName: this.customer.lastName,
  // measurements: this.customer.measurements,
  // phone: this.customer.phone,
  // postalCode: this.customer.postalCode,
  // prefix: this.customer.prefix,
  // role: this.customer.role,
  // salesforceId: this.customer.salesforceId,
  // need to send both over for now
  //   ___________________________________________________
  //    customer.sessionToken = await firebase
  //      .auth()
  //      .currentUser.getIdToken(/* forceRefresh */ true);

  //    const response = await axios.post(
  //      this.$store.state.baseUrl + '/create-account',
  //      customer
  //    );

  //    const database = await this.$db;

  //    let newCustomer = {
  //      id: response.data.userId,
  //      name: this.customer.firstName + ' ' + this.customer.lastName,
  //      role: this.customer.role,
  //    };

  //    if (this.customer.representative) {
  //      const representativeRef = database
  //        .collection('users')
  //        .doc(this.customer.representative);

  //      const representative = await representativeRef.get();

  //      if (representative.customers) {
  //        representative.customers.push(newCustomer);
  //      } else {
  //        representative.customers = [newCustomer];
  //      }

  //      await representativeRef.set(
  //        {
  //          customers: representative.customers,
  //        },
  //        {
  //          merge: true,
  //        }
  //      );
  //    } else {
  //      if (this.user.customers) {
  //        this.user.customers.push(newCustomer);
  //      } else {
  //        this.user.customers = [newCustomer];
  //      }

  //      await database
  //        .collection('users')
  //        .doc(this.user.id)
  //        .set(
  //          {
  //            customers: this.user.customers,
  //          },
  //          {
  //            merge: true,
  //          }
  //        );
  //    }
};
</script>
