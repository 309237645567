<!-- @format -->

<template>
  <div>
    <div class="loading loading--sm" v-if="!isLoaded">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div class="background-white container main-container" v-else>
      <h1 class="h1">{{ "Herd Characteristics" | translate }}</h1>
      <div class="card mt-4 mb-0">
        <div class="card-body">
          <div class="card-body__top d-flex justify-content-end">
            <p>* {{ "Field Required" | translate }}</p>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Evaluation Name" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="evaluation_name"
                  v-model="evaluation.name"
                  data-vv-as="Evaluation Name"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('evaluation_name')"
                  class="text-danger"
                >
                  <small>{{
                    "The Evaluation Name field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
          </div>

          <hr class="mt-2" />

          <h2 class="h2">{{ "Company" | translate }}</h2>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Company" | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  class="form-control"
                  v-model="evaluation.clientId"
                  @change="changeClient()"
                  name="company"
                  data-vv-as="Company"
                  v-validate="'required'"
                >
                  <option disabled :value="null">
                    {{ "Select" | translate }}
                  </option>
                  <option @selected="newClient" value="manual">
                    {{ "Add New Company" | translate }}
                  </option>
                  <option
                    v-for="(clientSelect, i) in squadClients"
                    :key="clientSelect._id + i"
                    :value="clientSelect._id"
                  >
                    {{ clientSelect.name }}
                  </option>
                </select>
                <span
                  v-show="errors.has('company')"
                  class="text-danger"
                >
                  <small>{{
                    "The Company field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <div
                class="form-group"
                v-if="evaluation.clientId == 'manual'"
              >
                <label class="mb-1"
                  >{{ "Company Name" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  :readonly="!showDairyFields"
                  type="text"
                  class="form-control"
                  name="company_name"
                  v-model="clientName"
                  data-vv-as="Company Name"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('company_name')"
                  class="text-danger"
                >
                  <small>{{
                    "The Company Name field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Address 1" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="company_address_line_1"
                  v-model="clientAddress"
                  data-vv-as="Address 1"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('company_address_line_1')"
                  class="text-danger"
                >
                  <small>{{
                    "The Address 1 field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Country" | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  v-if="client"
                  :readonly="!showClientFields"
                  @change="client.state = null"
                  class="form-control"
                  v-model="(client || {}).country"
                  name="company_country"
                  data-vv-as="Country"
                  v-validate="'required'"
                >
                  <option :value="null" disabled>
                    {{ "Select" | translate }}
                  </option>
                  <option
                    v-for="(country, index) in $store.state.misc
                      .countriesPrimary"
                    :key="index"
                    :value="index"
                  >
                    {{ country | translate }}
                  </option>
                  <option disabled>------------------</option>
                  <option
                    v-for="(country, index) in $store.state.misc
                      .countries"
                    :key="country"
                    :value="index"
                  >
                    {{ country | translate }}
                  </option>
                </select>
                <span
                  v-show="errors.has('company_country')"
                  class="text-danger"
                >
                  <small>{{
                    "The Country field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div v-if="client" class="form-group">
                <label class="mb-1">
                  {{ "State/Province" | translate }}
                </label>
                <select
                  v-if="
                    misc.states[client.country] &&
                    Object.keys(misc.states[client.country]).length
                  "
                  :readonly="!showClientFields"
                  class="form-control"
                  v-model="client.state"
                >
                  <option :value="null" disabled>
                    {{ "Select" | translate }}
                  </option>
                  <template
                    v-if="
                      misc.states[client.country] &&
                      Object.keys(misc.states[client.country].primary)
                        .length
                    "
                  >
                    <option
                      v-for="(state, abbr) in misc.states[
                        client.country
                      ].primary"
                      :key="abbr"
                      :value="abbr"
                    >
                      {{ state }}
                    </option>
                    <option disabled>------------------</option>
                  </template>

                  <option
                    v-for="(state, abbr) in misc.states[
                      client.country
                    ].regular"
                    :key="state"
                    :value="abbr"
                  >
                    {{ state }}
                  </option>
                </select>
                <input
                  v-else
                  v-model="client.state"
                  type="text"
                  class="form-control"
                  :readonly="!showClientFields"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1">{{
                  "Address 2" | translate
                }}</label>
                <input
                  :readonly="!showClientFields"
                  type="text"
                  class="form-control"
                  v-model="(client || {}).address_2"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "City" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="clientCity"
                  name="company_city"
                  data-vv-as="City"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('company_city')"
                  class="text-danger"
                >
                  <small>{{
                    "The City field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Postal Code" | translate }}
                </label>
                <input
                  :readonly="!showClientFields"
                  type="text"
                  class="form-control"
                  v-model="clientPostal"
                  name="company_postal_code"
                  data-vv-as="Postal Code"
                />
                <!-- pattern="\d{5}-?(\d{4})?"  -->
                <span
                  v-show="errors.has('company_postal_code')"
                  class="text-danger"
                >
                  <small>{{
                    "The Postal Code field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
          </div>
          <div v-if="client">
            <div class="card-body__top">
              <h2 class="h2 card-body__top__h3">
                {{ "Branding Image" | translate }}
              </h2>
            </div>
            <div class="row card-section">
              <div class="col-md-12">
                <p v-if="!client.brandingImageBase64">
                  {{
                    "You can add an image to be included on all reports you generate."
                      | translate
                  }}
                </p>
                <div class="brandingImg">
                  <img
                    v-if="client.brandingImageBase64"
                    class="w-100 mb-3 mb-md-0"
                    :src="
                      client.brandingImageBase64.startsWith(
                        'data:image'
                      ) && dataURItoBlob(client.brandingImageBase64)
                    "
                    alt=""
                    ref="brandingImageBase64"
                  />
                  <i
                    v-if="client.brandingImageBase64"
                    class="fa fa-close"
                    @click="() => removeBrandingImage('client')"
                  ></i>
                </div>
              </div>
              <div class="col-md-12">
                <label
                  for="upload-photo"
                  class="btn btn-primary btn-full--sm"
                  >{{ "Upload Image" | translate }}</label
                >
                <input
                  ref="fileInput"
                  class=""
                  id="upload-photo"
                  @change="(e) => fileUpload(e, 'client')"
                  type="file"
                  name="pic"
                  accept="image/*"
                />
                <p class="p--inline">
                  {{ "Max upload size: 5MB (JPG, PNG)" | translate }}
                </p>
              </div>
            </div>
          </div>
          <hr class="mt-2" />

          <h2 class="h2">{{ "Dairy Location" | translate }}</h2>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Dairy Name" | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  :readonly="!showAllFields"
                  class="form-control"
                  v-model="evaluation.dairyLocationId"
                  @change="changeDairyLocation()"
                  data-vv-as="Dairy Name"
                  name="dairy_name"
                  v-validate="'required'"
                >
                  <option disabled :value="null">
                    {{ "Select" | translate }}
                  </option>
                  <option @selected="newDairyLocation" value="manual">
                    {{ "Add New Dairy Location" | translate }}
                  </option>
                  <option
                    v-for="locationSelect in squadDairyLocations"
                    :key="locationSelect.id"
                    :value="locationSelect.id"
                  >
                    {{ locationSelect.name }}
                  </option>
                </select>
                <span
                  v-show="errors.has('dairy_name')"
                  class="text-danger"
                >
                  <small>{{
                    "The Dairy Location field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <div
                class="form-group"
                v-if="evaluation.dairyLocationId == 'manual'"
              >
                <label class="mb-1"
                  >{{ "Dairy Name" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  :readonly="!showDairyFields"
                  type="text"
                  class="form-control"
                  v-model="dairyLocationName"
                  data-vv-as="Dairy Name"
                  name="dairy_new_name"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('dairy_new_name')"
                  class="text-danger"
                >
                  <small
                    >{{
                      "The Dairy Name field is required." | translate
                    }}
                  </small>
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Address 1" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  :readonly="!showDairyFields"
                  type="text"
                  class="form-control"
                  v-model="dairyLocationAddress"
                  name="address_1"
                  data-vv-as="Address 1"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('address_1')"
                  class="text-danger"
                >
                  <small>{{
                    "The Address 1 field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Country" | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  v-if="dairyLocation"
                  :readonly="!showDairyFields"
                  @change="dairyLocation.state = null"
                  class="form-control"
                  v-model="dairyLocation.country"
                  name="dairy_country"
                  data-vv-as="Country"
                  v-validate="'required'"
                >
                  <option :value="null" disabled>
                    {{ "Select" | translate }}
                  </option>
                  <option
                    v-for="(country, index) in $store.state.misc
                      .countriesPrimary"
                    :key="country + index"
                    :value="index"
                  >
                    {{ country | translate }}
                  </option>
                  <option disabled>------------------</option>
                  <option
                    v-for="(country, index) in $store.state.misc
                      .countries"
                    :key="index + country"
                    :value="index"
                  >
                    {{ country | translate }}
                  </option>
                </select>
                <span
                  v-show="errors.has('dairy_country')"
                  class="text-danger"
                >
                  <small>{{
                    "The Country field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div v-if="dairyLocation" class="form-group">
                <label class="mb-1">
                  {{ "State/Province" | translate }}
                </label>

                <select
                  v-if="
                    dairyLocation &&
                    misc.states[dairyLocation.country]
                  "
                  :readonly="!showDairyFields"
                  class="form-control"
                  v-model="dairyLocation.state"
                >
                  <template
                    v-if="
                      misc.states[dairyLocation.country] &&
                      Object.keys(
                        misc.states[dairyLocation.country].primary
                      ).length
                    "
                  >
                    <option :value="null" disabled>
                      {{ "Select" | translate }}
                    </option>
                    <option
                      v-for="(state, abbr) in misc.states[
                        dairyLocation.country
                      ].primary"
                      :key="state + abbr"
                      :value="abbr"
                    >
                      {{ state }}
                    </option>
                    <option disabled>------------------</option>
                  </template>
                  <option
                    v-for="(state, abbr) in misc.states[
                      dairyLocation.country
                    ].regular"
                    :key="state"
                    :value="abbr"
                  >
                    {{ state }}
                  </option>
                </select>
                <input
                  v-else
                  v-model="dairyLocation.state"
                  type="text"
                  class="form-control"
                  :readonly="!showDairyFields"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1">{{
                  "Address 2" | translate
                }}</label>
                <input
                  :readonly="!showDairyFields"
                  type="text"
                  class="form-control"
                  v-model="dairyLocation.address_line_2"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "City" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  :readonly="!showDairyFields"
                  type="text"
                  class="form-control"
                  v-model="dairyLocationCity"
                  name="city"
                  data-vv-as="City"
                  v-validate="'required'"
                />
                <span v-show="errors.has('city')" class="text-danger">
                  <small>{{
                    "The City field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Postal Code" | translate }}
                </label>
                <input
                  :readonly="!showDairyFields"
                  type="text"
                  class="form-control"
                  v-model="dairyLocationPostal"
                  name="postal_code"
                  data-vv-as="Postal Code"
                />
                <span
                  v-show="errors.has('postal_code')"
                  class="text-danger"
                >
                  <small>{{
                    "The Postal Code field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
          </div>

          <hr class="mt-2" />

          <h2 class="h2">{{ "Contact" | translate }}</h2>

          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Name" | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  :readonly="!showAllFields"
                  class="form-control"
                  v-model="evaluation.contactId"
                  data-vv-as="Contact Name"
                  @change="changeContact()"
                  v-validate="'required'"
                >
                  <option disabled :value="null">
                    {{ "Select" | translate }}
                  </option>
                  <option @selected="newContact" value="manual">
                    {{ "Add New Contact" | translate }}
                  </option>
                  <option
                    v-for="contactSelect in squadContacts"
                    :key="contactSelect.id"
                    :value="contactSelect.id"
                  >
                    {{ contactSelect && contactSelect.name }}
                  </option>
                </select>
                <span
                  v-show="errors.has('contact_name')"
                  class="text-danger"
                >
                  <small>{{
                    "The Name field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>

            <div class="offset-lg-12"></div>

            <div
              v-if="evaluation.contactId === 'manual'"
              class="col-lg-12"
            >
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Name" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  :readonly="!showContactFields"
                  type="text"
                  class="form-control"
                  v-model="contactName"
                  name="last_name"
                  data-vv-as="Last Name"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('last_name')"
                  class="text-danger"
                >
                  <small>{{
                    "The Name field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Email 1" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  :readonly="!showContactFields"
                  type="email"
                  class="form-control"
                  v-model="contactEmail"
                  name="email_address_1"
                  data-vv-as="Email 1"
                  v-validate="'required|email'"
                />
                <span
                  v-show="errors.has('email_address_1')"
                  class="text-danger"
                >
                  <small>{{
                    "The Email 1 field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">{{
                  "Email 2" | translate
                }}</label>
                <input
                  :readonly="!showContactFields"
                  type="email"
                  class="form-control"
                  v-model="(contact || {}).email_2"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1">{{ "Title" | translate }}</label>
                <input
                  :readonly="!showContactFields"
                  type="text"
                  class="form-control"
                  v-model="(contact || {}).title"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Business Phone" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  :readonly="!showContactFields"
                  type="tel"
                  class="form-control"
                  v-model="contactBusiness"
                  name="business_phone"
                  data-vv-as="Business Phone"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('business_phone')"
                  class="text-danger"
                >
                  <small>{{
                    "The Business Phone field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">{{ "Mobile" | translate }}</label>
                <input
                  :readonly="!showContactFields"
                  type="tel"
                  class="form-control"
                  v-model="(contact || {}).mobile_phone"
                />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">{{ "Other" | translate }}</label>
                <input
                  :readonly="!showContactFields"
                  type="tel"
                  class="form-control"
                  v-model="(contact || {}).other"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">{{ "Fax" | translate }}</label>
                <input
                  :readonly="!showContactFields"
                  type="tel"
                  class="form-control"
                  v-model="(contact || {}).fax"
                />
              </div>
            </div>
          </div>

          <hr class="mt-2" />

          <h2 class="h2">{{ "Characteristics" | translate }}</h2>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Consultation Date" | translate }}
                  <span class="required">*</span>
                </label>
                <vuejs-datepicker
                  v-on:input="convertDate"
                  :value="evaluation.data.consultation_date"
                  :typeable="true"
                  input-class="form-control date-picker"
                  data-vv-as="Consultation Date"
                  name="consultation_date"
                  v-model="evaluation.data.consultation_date"
                  v-validate="'required'"
                  :format="formatDate"
                  :language="selectedLanguage"
                ></vuejs-datepicker>

                <span
                  v-show="errors.has('consultation_date')"
                  class="text-danger"
                >
                  <small>{{
                    "The Consultation Date field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Herd Size" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="number"
                  inputmode="numeric"
                  @keypress="$isNumber"
                  min="1"
                  class="form-control"
                  data-vv-as="Herd Size"
                  name="herd_size"
                  v-model="evaluation.data.herdSize"
                  v-validate="'required'"
                />
                <div
                  v-show="errors.has('herd_size')"
                  class="text-danger"
                >
                  <small>{{
                    "The Herd Size field is required." | translate
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1">{{
                  "Nutritionist" | translate
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  name="nutritionist"
                  v-model="evaluation.data.nutritionist"
                />
              </div>
            </div>
            <measurement-selection-radio
              v-model="evaluation.data.measurements"
            />
            <!-- start -->

            <div class="col-lg-6">
              <div class="form-group">
                <label class="d-block"
                  >{{
                    "Is this evaluation for training/testing purposes?"
                      | translate
                  }}
                  <span class="required">*</span>
                </label>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="is_for_testing"
                      :value="true"
                      data-vv-as="Is For Testing"
                      v-validate="'required'"
                      v-model="evaluation.isForTesting"
                    />
                    {{ "Yes" | translate }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="is_for_testing"
                      :value="false"
                      data-vv-as="Is For Testing"
                      v-validate="'required'"
                      v-model="evaluation.isForTesting"
                    />
                    {{ "No" | translate }}
                  </label>
                </div>
                <span
                  v-if="errors.has('is_for_testing')"
                  class="text-danger d-block"
                >
                  <small>{{
                    "The training/testing purposes field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <!-- end -->
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Currency" | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  name="currency"
                  class="form-control"
                  v-model="evaluation.data.currency"
                  data-vv-as="Currency"
                  v-validate="'required'"
                >
                  <option disabled :value="null">
                    {{ "Select" | translate }}
                  </option>
                  <option
                    v-for="(currency, index) in $store.state.misc
                      .currencies"
                    :key="index"
                    :value="index"
                  >
                    {{ currency | translate }}
                  </option>
                </select>
                <span
                  v-show="errors.has('currency')"
                  class="text-danger"
                >
                  <small>{{
                    "The Currency field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">{{
                  "Veterinarian" | translate
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="evaluation.data.veterinarian"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">
                  {{
                    $t("Milk Production") +
                    " (" +
                    $t(
                      $getEquivalentUnit(
                        evaluation.data.measurements,
                        "kg"
                      )
                    ) +
                    $t("/day)")
                  }}
                  <span class="required">*</span>
                </label>
                <input
                  name="computed_milk_production"
                  :data-vv-as="$t('Milk Production')"
                  type="number"
                  inputmode="decimal"
                  class="form-control error"
                  v-model.number="computed_milk_production"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('computed_milk_production')"
                  class="text-danger d-block"
                >
                  <small>{{
                    errors.first("computed_milk_production")
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1"
                  >{{ "Milk Price" | translate }}
                  <span>
                    {{
                      ("(" +
                        computed_currency +
                        ")" +
                        " / " +
                        $t(
                          $getEquivalentUnit(
                            evaluation.data.measurements,
                            "kg"
                          )
                        ))
                        | translate
                    }}
                    <span class="required">*</span>
                  </span>
                </label>
                <input
                  name="computed_milk_price"
                  type="number"
                  :data-vv-as="$t('Milk Price')"
                  inputmode="decimal"
                  class="form-control"
                  v-model.number="computed_milk_price"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('computed_milk_price')"
                  class="text-danger d-block"
                >
                  <small>{{
                    errors.first("computed_milk_price") | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">{{
                  "Hoof Trimmer" | translate
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  name="trimmer"
                  v-model="evaluation.data.trimmer"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="requiredFieldsMessage === true"
          class="alert alert-danger mt-4 mb-0"
          role="alert"
        >
          {{ "Please fill out the required fields!" | translate }}
        </div>

        <div
          v-if="errorMessage === true"
          class="alert alert-danger mt-4 mb-0"
          role="alert"
        >
          {{ "Sorry! Something went wrong." | translate }}
        </div>

        <div class="row d-flex">
          <div class="order-2 order-md-1 col-md-12">
            <router-link
              to="/"
              class="btn btn-secondary btn-full--sm mt-2 mt-md-4"
              >{{ "Back" | translate }}</router-link
            >
          </div>
          <div class="order-1 order-md-2 col-md-12">
            <button
              @click="nextStep"
              class="btn btn-primary btn-full--sm mt-4 float-right"
            >
              {{ "Next" | translate }}
              <span v-if="loader === true" class="loader ml-1"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import vuejsDatepicker from "vuejs-datepicker";

import {
  GET_EVALUATION_BY_ID,
  GET_DAIRY_LOCATION_BY_CLIENT_ID_GETTER,
  GET_CONTACT_BY_CLIENT_ID_GETTER,
  GET_ADD_EVALUATION_IN_DB,
  GET_ADD_CLIENT_INTO_DB,
  GET_UPDATE_CLIENT,
  GET_CLIENT_BY_ID_GETTER,
  GET_UPDATE_EVALUATION,
  SET_EVALUATION_BY_ID,
  GET_EVALUATION_BY_ID_GETTER,
  GET_USERS_BY_ID_GETTER,
  SET_ADD_EVALUATION_IN_DB,
} from "../../store/types";

import { newObjectId } from "@/helperFunctions.js";
import imageUploadMixin from "@/mixins/imageUploadFn.js";
import Evaluation from "@/libs/Classes/Evaluation.js";
import MeasurementSelectionRadio from "@/components/common/MeasurementSelectionRadio.vue";
import IndicatorForTestingEvaluation from "@/components/common/IndicatorForTestingEvaluation.vue";
import { zh, fr, es, en, de, ptBR, pl, it, ru, nl  } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    vuejsDatepicker,
    MeasurementSelectionRadio,
    IndicatorForTestingEvaluation,
  },
  mixins: [imageUploadMixin],

  data() {
    return {
      user: {},
      ch:zh,
      fr,
      es,
      en,
      de,
      pt:ptBR,
      pl,
      it,
      ru,
      nl,
      loaded: false,
      dairyLocations: [],
      contacts: [],
      contactBusiness: null,
      contactEmail: null,
      clientName: null,
      clientCity: null,
      clientAddress: null,
      clientPostal: null,
      contactName: null,
      dairyLocationAddress: null,
      dairyLocationCity: null,
      dairyLocationName: null,
      dairyLocationPostal: null,
      errorMessage: false,
      requiredFieldsMessage: false,
      evaluation: null,
      evaluationClass: null,
      groupClass: null,
      herdCharacteristics: null,
      isCheckAll: false,
      isLoaded: true,
      loader: false,
      default_evaluation: {
        id: this.$generateId(),
        name: null,
        userId: null,
        dairyLocationId: null,
        contactId: null,
        clientId: null,
        clientData: null,
        contacts: [],
        groups: {},
        mixMeasurements: null,
        isForTesting: null,
        data: {
          currency: null,
        },
        report: {
          assessors: [],
          groups: [],
          file_name: "Report 1", // Not sure if needed
          file_size: 234444, // Not sure if needed
          executive_summary_title: null,
          executive_summary_content: null,
          output_data: {}, // object
          date_created: 1527795454,
        },
        date_created: 1527795454,
        date_updated: 1527795454,
      },
      client: {
        id: this.$generateId(),
        country: null,
        state: null,
        name: null,
        address_2: null,
      },
      dairyLocation: {
        id: this.$generateId(),
        country: null,
        state: null,
      },
      contact: {
        id: this.$generateId(),
        email_2: null,
        title: null,
        mobile_phone: null,
        other: null,
        fax: null,
      },
    };
  },

  mounted() {
    setTimeout(() => {
      const { userId: paramUserId } = this.$route.params;
      const userId = paramUserId ? paramUserId : this.userId;
      this.user = this[GET_USERS_BY_ID_GETTER](userId);
      // setTimeout(() => {
      if (this.userId && paramUserId) {
        this.editTheEvaluation();
      } else {
        this.initializeNewEvaluation();
      }
      // }, 1000);

      if (!this.evaluation.data.measurements) {
        this.evaluation.data.measurements = this.user.measurements;
        this.evaluation.data.mixMeasurements = this.user.measurements;
      }

      if (!this.evaluation.data.currency) {
        this.evaluation.data.currency = this.user.currency;
      }

      this.evaluationClass = new Evaluation(this, this.evaluation);
    }, 1000);
  },
  computed: {
    ...mapState({
      evaluations: (state) => state.evaluations.evaluations,
      userId: (state) => state.users.user._id,
      // user: state => state.users.user,
      clients: (state) => state.clients.clients,
      assessorTypes: (state) => state.assessorTypes,
      misc: (state) => state.misc,
      lang: (state) => state.i18n.locale,
    }),

    ...mapGetters("evaluations", [GET_EVALUATION_BY_ID_GETTER]),
    ...mapGetters("users", [GET_USERS_BY_ID_GETTER]),

    ...mapGetters("clients", [
      GET_DAIRY_LOCATION_BY_CLIENT_ID_GETTER,
      GET_CONTACT_BY_CLIENT_ID_GETTER,
      GET_CLIENT_BY_ID_GETTER,
    ]),

    selectedLanguage() {
      return this[this.lang];
    },

    clientImgSrc() {
      console.log(
        this.client.brandingImageBase64.startsWith("data:image")
      );
      return this.client.brandingImageBase64.startsWith("data:image")
        ? this.dataURItoBlob(this.client.brandingImageBase64)
        : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
    },

    squadClients() {
      const clientsAsArray = Object.values(this.clients || {}) || [];

      return (
        clientsAsArray.filter(
          (client) => typeof client.name !== "undefined"
        ) || []
      );
    },

    squadDairyLocations() {
      const dairyLocationsAsArray =
        Object.values(this.dairyLocations || {}) || [];

      return (
        dairyLocationsAsArray.filter(
          (location) => location && location.name
        ) || []
      );
    },

    squadContacts() {
      const contactsAsArray =
        Object.values(this.contacts || {}) || [];
      return (
        contactsAsArray.filter(
          (contact) => contact && contact.name
        ) || []
      );
    },

    showAllFields() {
      if (this.evaluation.clientId === null) return false;
      return true;
    },

    showDairyFields() {
      if (this.evaluation.clientId === null) return false;
      return true;
    },

    showContactFields() {
      if (this.evaluation.clientId === null) return false;
      return true;
    },

    showClientFields() {
      return true;
    },

    computed_milk_price: {
      cache: false,
      get() {
        return this.evaluationClass.getMilkPrice(false, true);
      },
      set(newValue) {
        return this.evaluationClass.setMilkPrice(newValue);
      },
    },

    computed_milk_production: {
      cache: false,
      get() {
        return this.evaluationClass.getInitiallMilkProduction(false);
      },
      set(newValue) {
        return this.evaluationClass.setInitiallMilkProduction(
          newValue
        );
      },
    },

    computed_currency() {
      if (this.evaluation.data.currency === null) {
        return "currency";
      } else {
        return this.evaluation.data.currency;
      }
    },
  },

  watch: {
    clientName(val) {
      this.client.name = val;
    },
    clientCity(val) {
      this.client.city = val;
    },
    clientAddress(val) {
      this.client.address_1 = val;
    },
    clientPostal(val) {
      this.client.postal_code = val;
    },
    dairyLocationName(val) {
      this.dairyLocation.name = val;
    },
    dairyLocationAddress(val) {
      this.dairyLocation.address_1 = val;
    },
    dairyLocationCity(val) {
      this.dairyLocation.city = val;
    },
    dairyLocationPostal(val) {
      this.dairyLocation.postal_code = val;
    },
    contactName(val) {
      this.contact.name = val;
    },
    contactEmail(val) {
      this.contact.email_1 = val;
    },
    contactBusiness(val) {
      this.contact.business_phone = val;
    },
  },

  methods: {
    ...mapActions("evaluations", [
      GET_EVALUATION_BY_ID,
      GET_ADD_EVALUATION_IN_DB,
      GET_UPDATE_EVALUATION,
    ]),
    ...mapActions("clients", [
      GET_ADD_CLIENT_INTO_DB,
      GET_UPDATE_CLIENT,
    ]),

    formatDate() {
      return this.$store.getters.getDate(
        this.evaluation.data.consultation_date
      );
    },

    initializeNewEvaluation() {
      // if evaluationId is not set, then we are creating a new evaluation
      this.evaluation = _.cloneDeep(this.default_evaluation);
      this.evaluation.data.consultation_date = new Date().getTime();
      this.evaluation.date_created = new Date().getTime();
      this.evaluation.date_updated = new Date().getTime();
      this.evaluation.userId = this.$route.params.userId;
    },
    async editTheEvaluation() {
      console.log("edit evaluation");
      const { userId: paramUserId, evaluationId } =
        this.$route.params;
      this.evaluation =
        this[GET_EVALUATION_BY_ID_GETTER](evaluationId);
      this.evaluation.userId = paramUserId; // set the evaluation's userId just in case it is not set already
      this.evaluation.isForTesting =
        this.evaluation.isForTesting ?? null;

      console.log({ evaluation: this.evaluation });

      if (!this.evaluation) {
        this.$router.push(
          `/users/${this.userId}/evaluations?errorCode=evaluation/not-found`
        );
      }
      if (this.evaluation.clientId) {
        this.client = this.evaluation.clientData;
        if (this.client) {
          this.clientName = this.client.name;
          this.clientCity = this.client.city;
          this.clientAddress = this.client.address_1;
          this.clientPostal = this.client.postal_code;
        }

        this.dairyLocations = this.client.dairy_locations;
        this.changeDairyLocation();

        this.contacts = this.client.contacts;
        this.changeContact();
        if (this.contact) {
          this.contactName = this.contact.name;
          this.contactEmail = this.contact.email_1;
          this.contactBusiness = this.contact.business_phone;
        }
        // check if evaluation doesn't contain the mixedMeasurement property, add it
        if (!this.evaluation.data.mixMeasurements) {
          this.evaluation.data.mixMeasurements =
            this.user.measurements;
        }

        this.dairyLocationName = this.dairyLocation.name;
        this.dairyLocationAddress = this.dairyLocation.address_1;
        this.dairyLocationCity = this.dairyLocation.city;
        this.dairyLocationPostal = this.dairyLocation.postal_code;
      }
    },

    getClientById(id) {
      return this[GET_CLIENT_BY_ID_GETTER](id);
    },

    getContactById(clientId, id) {
      this[GET_CONTACT_BY_CLIENT_ID_GETTER](clientId, id);
    },

    async nextStep() {
      try {
        this.loader = true;
        const { userId = this.userId } = this.$route.params

        this.$validator.validateAll().then(async (result) => {
          // @TODO Refactor this *validation*
          if (
            !this.computed_milk_price ||
            !this.computed_milk_production ||
            !this.evaluation.name ||
            !this.evaluation.data.herdSize ||
            this.evaluation.isForTesting === null
          ) {
            result = false;
          }

          if (result === true) {
            if (!this.evaluations.length) {
            }
            const createClient = this.createClient();

            this.evaluation = {
              _id: newObjectId(),
              ...this.evaluation,
              contactId: this.contact.id,
              dairyLocationId: this.dairyLocation.id,
              clientId: createClient._id,
              clientData: createClient,
              userId,
            };

             if (!this.evaluation.data.mixMeasurements) { 
                this.evaluation.data.mixMeasurements = this.evaluation.data.measurements;
              }

            const { evaluationId: updateTheEvaluation } = this.$route.params;

            this.$store.commit(
              `evaluations/${SET_ADD_EVALUATION_IN_DB}`,
              this.evaluation
            );

            this[
              updateTheEvaluation
                ? GET_UPDATE_EVALUATION
                : GET_ADD_EVALUATION_IN_DB
            ](this.evaluation);

            this.loader = false;

            this.$router.push({
              path: `/users/${this.userId}/evaluations/${this.evaluation._id}/groups`,
            });
          } else {
            this.requiredFieldsMessage = true;
            this.loader = false;
            setTimeout(() => {
              this.requiredFieldsMessage = false;
            }, 5000);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    createClient() {
      try {
        const isCreateNew = this.evaluation.clientId === "manual";

        const clientWithAllData = {
          _id: newObjectId(),
          ...this.client,
          contacts: {
            ...this.contacts,
            [this.contact.id]: this.contact,
          },
          dairy_locations: {
            ...this.dairyLocations,
            [this.dairyLocation.id]: this.dairyLocation,
          },
        };

        // TODO: Need to verify that on update user should save contact and dairy location proper data.
        if (isCreateNew)
          this[GET_ADD_CLIENT_INTO_DB](clientWithAllData);
        else this[GET_UPDATE_CLIENT](clientWithAllData);

        return clientWithAllData;
      } catch (error) {
        throw error;
      }
    },
    newClient() {
      this.client = {
        userId: this.userId,
        country: null,
        state: null,
      };
      this.dairyLocations = {
        userId: this.userId,
      };
      this.contacts = {
        userId: this.userId,
      };
      this.client.id = this.$generateId();
      this.$validator.reset();
    },
    convertDate() {
      this.evaluation.data.consultation_date = new Date(
        this.evaluation.data.consultation_date
      ).getTime();
    },
    newDairyLocation() {
      this.dairyLocation = {
        id: this.$generateId(),
        name: null,
        address_1: null,
        address_2: null,
        country: null,
        state: null,
        city: null,
        postal_code: null,
        date_created: null,
        date_updated: null,
        userId: this.userId,
      };
      this.dairyLocationName = null;
      this.dairyLocationAddress = null;
      this.dairyLocationCity = null;
      this.dairyLocationPostal = null;

      this.$validator.reset();
    },
    newContact() {
      this.contact = {
        id: this.$generateId(),
        title: null,
        email_1: null,
        email_2: null,
        business_phone: null,
        mobile_phone: null,
        other: null,
        fax: null,
        date_created: null,
        date_updated: null,
        userId: this.userId,
      };
      this.contactName = null;
      this.contactEmail = null;
      this.contactBusiness = null;

      this.$validator.reset();
    },
    changeClient() {
      this.evaluation.dairyLocationId = "manual";
      this.evaluation.contactId = "manual";

      if (this.evaluation.clientId === "manual") {
        this.newClient();
        this.newDairyLocation();
        this.newContact();
      } else {
        const client = this.getClientById(this.evaluation.clientId);
        if (client) {
          this.client = _.cloneDeep(client);
        }

        this.dairyLocations = this.client.dairy_locations;

        this.contacts = this.client.contacts;
        this.contact =
          this.client.contacts[this.evaluation.contactId];
        this.newDairyLocation();
        this.newContact();
      }
      this.clientName = this.client.name;
      this.clientCity = this.client.city;
      this.clientAddress = this.client.address_1;
      this.clientPostal = this.client.postal_code;

      this.$validator.reset();
    },
    changeDairyLocation() {
      this.$validator.pause();
      if (this.evaluation.dairyLocationId === "") {
        return;
      }
      if (this.evaluation.dairyLocationId == "manual") {
        this.newDairyLocation();
      } else if (this.dairyLocations) {
        this.dairyLocation =
          this.dairyLocations[this.evaluation.dairyLocationId];
        this.dairyLocationName = this.dairyLocation.name;
        this.dairyLocationAddress = this.dairyLocation.address_1;
        this.dairyLocationCity = this.dairyLocation.city;
        this.dairyLocationPostal = this.dairyLocation.postal_code;
      }
      this.$validator.reset();
    },
    changeContact() {
      if (this.evaluation.contactId === "") {
        return;
      }
      if (this.evaluation.contactId == "manual") {
        this.newContact();
      } else {
        const contact = this.contacts[this.evaluation.contactId];
        if (contact) {
          this.contact = contact;
          this.contactName = contact.name;
          this.contactEmail = contact.email_1;
          this.contactBusiness = contact.business_phone;
        }
      }
      this.$validator.reset();
    },
    //Upload a photo to the local
  },
};
</script>
