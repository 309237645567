var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoaded),expression:"!isLoaded"}],staticClass:"loading"},[_c('div',{staticClass:"double-bounce1"}),_c('div',{staticClass:"double-bounce2"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded),expression:"isLoaded"}],staticClass:"main-container main-container--margin-bt container"},[_c('div',{staticClass:"card mb-0"},[_c('h1',{staticClass:"h1"},[_vm._v(_vm._s(_vm._f("translate")('My Profile')))]),_c('div',{staticClass:"card-body mt-4"},[_c('general-profile',{attrs:{"companies":_vm.userCompanies},model:{value:(_vm.profile),callback:function ($$v) {_vm.profile=$$v},expression:"profile"}}),(_vm.$route.path.search(/admin/) === -1)?_c('div',[_c('div',{staticClass:"card-body__top"},[_c('h2',{staticClass:"h2 card-body__top__h3"},[_vm._v(" "+_vm._s(_vm._f("translate")('Branding Image'))+" ")])]),_c('hr'),_c('div',{staticClass:"row card-section"},[_c('div',{staticClass:"col-md-12"},[(!_vm.profile.brandingImageBase64)?_c('p',[_vm._v(" "+_vm._s(_vm._f("translate")('You can add an image to be included on all reports you generate.'))+" ")]):_vm._e(),_c('div',{staticClass:"brandingImg"},[_c('img',{ref:"brandingImageBase64",staticClass:"w-100 mb-3 mb-md-0",attrs:{"src":(_vm.profile.brandingImageBase64 || '').startsWith(
                      'data:image'
                    ) && _vm.dataURItoBlob(_vm.profile.brandingImageBase64),"alt":""}}),(_vm.profile.brandingImageBase64)?_c('i',{staticClass:"fa fa-close",on:{"click":() => _vm.removeBrandingImage('profile')}}):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"btn btn-primary btn-full--sm",attrs:{"for":"upload-photo"}},[_vm._v(_vm._s(_vm._f("translate")('Upload Image')))]),_c('input',{ref:"fileInput",attrs:{"id":"upload-photo","type":"file","name":"pic","accept":"image/*"},on:{"change":e => _vm.fileUpload(e, 'profile')}}),_c('p',{staticClass:"p--inline"},[_vm._v("(JPG, PNG)")])])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{class:{
              'col-md-12': _vm.$route.path.search(/admin/) === -1,
              'col-md-24': _vm.$route.path.search(/admin/) === 1,
            }},[_c('password-reset',{attrs:{"user":_vm.profile},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]),(_vm.lastUpdatedDate)?_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"card-body__top"},[_c('h2',{staticClass:"h2 card-body__top__h3"},[_vm._v(" "+_vm._s(_vm._f("translate")('Application Info'))+" ")])]),_c('hr'),_c('p',[_vm._v(_vm._s(_vm._f("translate")('Last updated'))+": "+_vm._s(_vm.$store.getters.getDate(_vm.lastUpdatedDate)))])]):_vm._e()],1),(_vm.confirmMessage)?_c('div',{staticClass:"alert alert-primary mt-4",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm._f("translate")('Updated Successfully'))+" ")]):_vm._e(),(_vm.errorMessage)?_c('div',{staticClass:"alert alert-danger mt-4",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm._f("translate")(_vm.errorMessageText))+" ")]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('button',{class:[
              'btn btn-primary btn-full--sm float-right',
              { 'mt-2': _vm.confirmMessage === true || _vm.errorMessage === true },
              { 'mt-4': _vm.confirmMessage === false && _vm.errorMessage === false },
            ],attrs:{"type":"button","data-toggle":"modal","data-target":"#updateModal"}},[_vm._v(" "+_vm._s(_vm._f("translate")('Update'))+" "),(_vm.loader)?_c('span',{staticClass:"loader ml-1"}):_vm._e()]),_c('div',{staticClass:"modal fade",attrs:{"id":"updateModal","tabindex":"-1","role":"dialog","aria-labelledby":"updateModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content edit-modal"},[_c('h2',{staticClass:"h2 text-center mb-0",attrs:{"id":"updateModalLabel"}},[_vm._v(" "+_vm._s(_vm._f("translate")('Enter Your Current Password'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("translate")('to Confirm Changes'))+" ")]),_c('div',{staticClass:"modal-body my-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],staticClass:"form-control",attrs:{"type":"password","name":"passwordConfirm","data-vv-as":"Password"},domProps:{"value":(_vm.passwordConfirm)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordConfirm=$event.target.value}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('passwordConfirm')),expression:"errors.has('passwordConfirm')"}],staticClass:"text-danger d-block"},[_c('small',[_vm._v(_vm._s(_vm._f("translate")('The Password field is required.')))])]),_c('div',{staticClass:"row d-flex"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-center align-items-center order-2 order-md-1"},[_c('a',{staticClass:"btn-cancel-modal",attrs:{"href":"#","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm._f("translate")('Cancel'))+" ")])]),_c('div',{staticClass:"col-md-12 order-1 order-md-2"},[_c('button',{staticClass:"btn btn-primary btn-full--sm mb-2 mb-md-0",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.updateProfile}},[_vm._v(" "+_vm._s(_vm._f("translate")('Save'))+" ")])])])])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }