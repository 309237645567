/**
 * Locomotion scoring is based on the observation of cows standing and walking (gait), with special emphasis on their back posture. This
 * system is intuitive and easy to use and implement. Use of locomotion scoring is effective for early detection of claw (hoof) disorders,
 * monitoring prevalence of lameness, comparing the incidence and severity of lameness between herds and identifying individual cows for
 * functional claw (hoof) trimming.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class Locomotion  extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;

        this.initializeConstants();
    }

    initializeConstants() {
        switch (this.getProductionType()) {
            case "confined_cattle":
                if (this.getScoringSystem() === 1) { // 5 Point Grading
                    this.CONSTANT_ONE = 1;
                    this.CONSTANT_TWO = 0.98;
                    this.CONSTANT_THREE = 0.959;
                    this.CONSTANT_FOUR = 0.907;
                    this.CONSTANT_FIVE = 0.848;
                } else if (this.getScoringSystem() === 0) { // 4 Point Grading
                    this.CONSTANT_ONE = 1;
                    this.CONSTANT_TWO = 0.98;
                    this.CONSTANT_THREE = 0.959;
                    this.CONSTANT_FOUR = 0.8775;
                    this.CONSTANT_FIVE = 0.8775;
                } else {
                    console.error("ERROR: No Locomotion Type NOT Accepted");
                }
                break;
            case "grazing_cattle":
                if (this.getScoringSystem() === 1) { //5 Point Grading
                    this.CONSTANT_ONE = 1;
                    this.CONSTANT_TWO = 0.99;
                    this.CONSTANT_THREE = 0.92;
                    this.CONSTANT_FOUR = 0.89;
                    this.CONSTANT_FIVE = 0.66;
                } else if (this.getScoringSystem() === 0) { // 4 Point Grading
                    this.CONSTANT_ONE = 1;
                    this.CONSTANT_TWO = 0.99;
                    this.CONSTANT_THREE = 0.92;
                    this.CONSTANT_FOUR = 0.775;
                    this.CONSTANT_FIVE = 0.775;
                } else {
                    console.error("ERROR: No Locomotion Type NOT Accepted");
                }
                break;
            default:
                // @TODO need to handle this issue
                // throw "ERROR: No production type found!";
        }
    }

    getSuggestedSampleSize() {
        return this.assessor.data.suggested_sample_size;
    }

    getEconomics() {
        return this.assessor.data.economics;
    }

    getProductionType() {
        return this.assessor.data.production_type;
    }

    getScoringSystem() {
        return this.assessor.data.locomotion_type;
    }

    getScore(scoreNumber) {
        return this.assessor.data['locomotion_score_'+scoreNumber];
    }

    setScore(scoreNumber, newScore) {
        this.assessor.data['locomotion_score_'+scoreNumber] = newScore;
    }

    getValAxisMajorUnit() {
          // calculate an initial guess at step size
          const ln10 = Math.log(10);
          const tempStep = Math.abs(this.getYearlyProfitPotential()) / 10;
          // get the magnitude of the step size
          const mag = Math.floor(Math.log(tempStep) / ln10);
          const magPow = Math.pow(10, mag);
          // calculate most significant digit of the new step size
          let magMsd = Math.round(tempStep / magPow + 0.5);
          // promote the MSD to either 1, 2, or 5
          if (magMsd > 5.0) {
              magMsd = 10.0;
          } else if (magMsd > 2.0) {
              magMsd = 5.0;
          } else if (magMsd > 1.0) {
              magMsd = 2.0;
          }

          return magMsd * magPow;
    }

    getTotalScore() {
        let totalScore = this.getScore(1) + this.getScore(2) + this.getScore(3);

        if(this.getScoringSystem() === 1) {
            totalScore += this.getScore(4);
            totalScore += this.getScore(5);
        } else {
            totalScore += this.getScore(45);
        }

        return totalScore;
    }

    getPercentageOfTotalScore(scoreNumber, returnNumber) {
        if ( returnNumber === undefined ) {
            returnNumber = false;
        }

        let percentageOfTotalScore = (this.getScore(scoreNumber) / this.getTotalScore() * 100);

        if(returnNumber === true) {
            return percentageOfTotalScore;
        }

        return percentageOfTotalScore.toFixed(1);
    }

    getScoreValue(scoreKey){
        return this.assessor.data[scoreKey];
    }


    getTarget(targetNumber) {
        return this.assessor.data['locomotion_target_' + targetNumber];
    }

    setTarget(targetNumber, newTargetScore) {
        return this.assessor.data['locomotion_target_'+targetNumber] = newTargetScore;
    }

    getTargetScore() {
        let totalScore = this.getTarget(1) + this.getTarget(2) + this.getTarget(3);

        if (this.getScoringSystem() === 1) {
            totalScore += this.getTarget(4);
            totalScore += this.getTarget(5);
        } else {
            totalScore += this.getTarget(45);
        }

        return totalScore;
    }

    getPercentageOfTargetScore(scoreNumber) {
        return ( this.getTarget(scoreNumber) / this.getTargetAverageLocomotionScore() ).toFixed(2);
    }

    getTargetAverageLocomotionScore() {
        let targetTotal = this.getTarget(1) + (this.getTarget(2) * 2) + (this.getTarget(3) * 3);

        //If the user selected Five point grading system:
        if (this.getScoringSystem() === 1) {
            targetTotal += (this.getTarget(4) * 4);
            targetTotal += (this.getTarget(5) * 5);
        } else {
            targetTotal += (this.getTarget(45) * 4.5);
        }

        return ( targetTotal / this.getTargetScore() ).toFixed(2);
    }

    getTargetDailyMilkLoss(returnNumber) {
        if(returnNumber === undefined) returnNumber = false;

        let averageScore = (
            (((this.getTarget(1)) / 100) * this.CONSTANT_ONE) +
            (((this.getTarget(2)) / 100) * this.CONSTANT_TWO) +
            (((this.getTarget(3)) / 100) * this.CONSTANT_THREE)
        );

        if (this.getScoringSystem() === 1) {
            averageScore += (((this.getTarget(4)) / 100) * this.CONSTANT_FOUR);
            averageScore += (((this.getTarget(5)) / 100) * this.CONSTANT_FIVE);
        } else {
            averageScore += (((this.getTarget(45)) / 100) * this.CONSTANT_FOUR);
        }

        let averageDailyMilkLoss = (
            (this.group.getMilkProduction(false) / averageScore) - this.group.getMilkProduction(false)
        );

        if(returnNumber === true) {
            return averageDailyMilkLoss;
        }

        return averageDailyMilkLoss.toFixed(2);
    }

    getAverageLocomotionScore() {
        let scoreTotal = this.getScore(1) + (this.getScore(2) * 2) + (this.getScore(3) * 3);

        //If the user selected Five point grading system:
        if (this.getScoringSystem() === 1) {
            scoreTotal += (this.getScore(4) * 4);
            scoreTotal += (this.getScore(5) * 5);
        } else {
            scoreTotal += (this.getScore(45) * 4.5);
        }

        return ( scoreTotal / this.getTotalScore() ).toFixed(2);
    }

    getAverageDailyMilkLoss(returnNumber) { // KG per Cow per Day
        if(returnNumber === undefined) returnNumber = false;

        let averageScore = (
            ((this.getScore(1) / this.getTotalScore()) * this.CONSTANT_ONE) +
            ((this.getScore(2) / this.getTotalScore()) * this.CONSTANT_TWO) +
            ((this.getScore(3) / this.getTotalScore()) * this.CONSTANT_THREE)
        );

        if (this.getScoringSystem() === 1) {
            averageScore += ((this.getScore(4) / this.getTotalScore()) * this.CONSTANT_FOUR);
            averageScore += ((this.getScore(5) / this.getTotalScore()) * this.CONSTANT_FIVE);
        } else {
            averageScore += ((this.getScore(45) / this.getTotalScore()) * this.CONSTANT_FOUR);
        }

        let averageDailyMilkLoss = (
            (this.group.getMilkProduction(false) / averageScore) - this.group.getMilkProduction(false)
        );

        if(returnNumber === true) {
            return averageDailyMilkLoss;
        }

        return averageDailyMilkLoss.toFixed(2);
    }

    getAverageDailyMilkLossPerHerd(returnNumber) {
        if(returnNumber === undefined) returnNumber = false;

        let averageDailyMilkLossPerHerd = (this.getAverageDailyMilkLoss(true) * this.group.getGroupSize());


        if ( returnNumber === true ) {
            return averageDailyMilkLossPerHerd;
        }

        return averageDailyMilkLossPerHerd.toFixed(2);
    }

    getLostRevenueDaily(returnNumber) { // Dollars per Group per Day
        if(returnNumber === undefined) returnNumber = false;

        let lostRevenueDaily = (this.evaluation.getMilkPrice(false, true) * this.getAverageDailyMilkLossPerHerd(true));

        if(returnNumber === true) {
            return parseFloat(lostRevenueDaily);
        }

        return lostRevenueDaily.toFixed(2);
    }

    getLostRevenueYearly(returnNumber) { // Dollars per Group per Year
        if(returnNumber === undefined) returnNumber = false;

        let lostRevenueYearly = (this.getLostRevenueDaily(true) * 365)

        if(returnNumber === true) {
            return parseFloat(lostRevenueYearly);
        }

        return lostRevenueYearly.toFixed(2);
    }

    getTargetMilkLossHerdYearly() {
        return (this.getTargetDailyMilkLoss(true) * 365);
    }

    getTargetCostHerdDaily() {
        return (this.evaluation.getMilkPrice(false, true) * this.getTargetDailyMilkLoss(true)) * this.group.getGroupSize();
    }

    getTargetCostHerdYearly() {
        return (this.getTargetCostHerdDaily() * 365);
    }

    getMilkLossYearly() {
        return (this.getAverageDailyMilkLossPerHerd() * 365).toFixed(2);
    }

    getMilkLossPotential() {
        return (this.getMilkLossYearly() - this.getTargetMilkLossHerdYearly()).toFixed(2);
    }

    getRevenueLostDaily() {
        return (this.getLostRevenueDaily() * this.getTargetCostHerdDaily()).toFixed(2);
    }

    getYearlyProfitPotential() {
        return (this.getLostRevenueYearly() - this.getTargetCostHerdYearly()).toFixed(2);
    }

};

export default Locomotion;
