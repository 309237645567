import BeddedPack from './BeddedPack';
import Biosecurity from './Biosecurity';
import FreeStall from './FreeStall';
import HygieneAssessor from './HygieneAssessor';
import Locomotion from './Locomotion';
import TimeBudget from './TimeBudget';
import HoldingArea from './HoldingArea';
import NutritionAndFeeding from './NutritionAndFeeding';
import TieStallStanchionHeatAbatement from './TieStallStanchionHeatAbatement';
import HoofTrimming from './HoofTrimming';
import Lesion from './Lesion';
import WalkingSurface from './WalkingSurface';
import Racetrack from './Racetrack';
import FreestallVentilation from './FreestallVentilation';
import TieStallStanchion from './TieStallStanchion';
import HeatAbatement from './HeatAbatement';
import DirtLot from './DirtLot';
import HoldingYardMilkShed from './HoldingYardMilkShed';
import Transition from './Transition';

import convert from 'convert-units';

class Group {

  constructor(app, evaluation, group) {
    this.app = app;
    this.evaluation = evaluation;
    this.group = group;
  }

  getId() {
    return this.group.id;
  }

  getName() {
    return this.group.name;
  }

  getGroupSize() {
    return parseInt(this.group.data.size);
  }

  getCowSize() {
    return this.group.data.cow_size;
  }

  getTypeOfAnimal() {
    return this.group.data.type_of_animal;
  }

  getAreMilkCowsInHerd() {
    let result = false;

    if (this.getTypeOfAnimal() === 'fresh' || this.getTypeOfAnimal() === 'mixed' || this.getTypeOfAnimal() === 'heifers-first-lactation' || this.getTypeOfAnimal() === 'lactating') {
      result = true;
    }
    return result;
  }

  getSampleSize() {
    const groupSize = parseInt(this.getGroupSize());

    if (groupSize >= 20 && groupSize < 30) {
      return 17;
    } else if (groupSize >= 30 && groupSize < 40) {
      return 23;
    } else if (groupSize >= 40 && groupSize < 50) {
      return 28;
    } else if (groupSize >= 50 && groupSize < 60) {
      return 33;
    } else if (groupSize >= 60 && groupSize < 70) {
      return 37;
    } else if (groupSize >= 70 && groupSize < 80) {
      return 41;
    } else if (groupSize >= 80 && groupSize < 90) {
      return 44;
    } else if (groupSize >= 90 && groupSize < 100) {
      return;
    } else if (groupSize >= 100 && groupSize < 110) {
      return 49;
    } else if (groupSize >= 110 && groupSize < 120) {
      return 52;
    } else if (groupSize >= 120 && groupSize < 130) {
      return 54;
    } else if (groupSize >= 130 && groupSize < 140) {
      return 55;
    } else if (groupSize >= 140 && groupSize < 150) {
      return 57;
    } else if (groupSize >= 150 && groupSize < 160) {
      return 59;
    } else if (groupSize >= 160 && groupSize < 170) {
      return 60;
    } else if (groupSize >= 170 && groupSize < 180) {
      return 62;
    } else if (groupSize >= 180 && groupSize < 190) {
      return 63;
    } else if (groupSize >= 190 && groupSize < 200) {
      return 64;
    } else if (groupSize >= 200 && groupSize < 210) {
      return 65;
    } else if (groupSize >= 210 && groupSize < 220) {
      return 66;
    } else if (groupSize >= 220 && groupSize < 230) {
      return 67;
    } else if (groupSize >= 230 && groupSize < 240) {
      return 68;
    } else if (groupSize >= 240 && groupSize < 250) {
      return 69;
    } else if (groupSize >= 250 && groupSize < 270) {
      return 70;
    } else if (groupSize >= 270 && groupSize < 280) {
      return 71;
    } else if (groupSize >= 280 && groupSize < 300) {
      return 72;
    } else if (groupSize >= 300 && groupSize < 350) {
      return 73;
    } else if (groupSize >= 350 && groupSize < 400) {
      return 76;
    } else if (groupSize >= 400 && groupSize < 450) {
      return 78;
    } else if (groupSize >= 450 && groupSize < 500) {
      return 79;
    } else if (groupSize >= 500 && groupSize < 550) {
      return 81;
    } else if (groupSize >= 550 && groupSize < 600) {
      return 82;
    } else if (groupSize >= 600 && groupSize < 650) {
      return 83;
    } else if (groupSize >= 650 && groupSize < 700) {
      return 84;
    } else if (groupSize >= 700 && groupSize < 800) {
      return 85;
    } else if (groupSize >= 800 && groupSize < 900) {
      return 86;
    } else if (groupSize >= 900 && groupSize < 1000) {
      return 87;
    } else if (groupSize >= 1000 && groupSize < 1150) {
      return 88;
    } else if (groupSize >= 1150 && groupSize < 1350) {
      return 89;
    } else if (groupSize >= 1350 && groupSize < 1600) {
      return 90;
    } else if (groupSize >= 1600 && groupSize < 1950) {
      return 91;
    } else if (groupSize >= 1950 && groupSize < 2500) {
      return 92;
    } else if (groupSize >= 2500 && groupSize < 3500) {
      return 93;
    } else if (groupSize >= 3500 && groupSize < 6000) {
      return 94;
    } else if (groupSize >= 6000 && groupSize < 17000) {
      return 95;
    } else if (groupSize >= 17000) {
      return 96;
    } else {
      return null;
    }
  }

  // @TODO: Optimize this
  getCowSizeLabel() {
    if (this.evaluation.getMeasurements() === 'metric') {
      switch (this.getCowSize()) {
        case '408.2-498.9':
          return '408-499';

        case '498.9-589.6':
          return '499-590';

        case '589.6-680.3':
          return '590-680';

        case '680.3-771':
          return '680-771';

        case '771-861.8':
          return '771-862';

        case '861.8-952.5':
          return '862-953';
      }
    } else {
      switch (this.getCowSize()) {
        case '408.2-498.9':
          return '900-1100';

        case '498.9-589.6':
          return '1100-1300';

        case '589.6-680.3':
          return '1300-1500';

        case '680.3-771':
          return '1500-1700';

        case '771-861.8':
          return '1700-1900';

        case '861.8-952.5':
          return '1900-2100';
      }
    }
  }

  getMilkProduction(roundPoint) {
    if (this.group.data.milkProduction === undefined || this.group.data.milkProduction === null) {
      return null; // this.group.data.milkProduction;
    }

    // if (roundPoint === false) {
      roundPoint = this.group.data.milkProduction.countDecimals() - 3;
      if (roundPoint < 0) roundPoint = 0;
    // }

    return convert(this.group.data.milkProduction)
      .from('kg')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg'))
      .toDecimal(roundPoint);
  }

  setMilkProduction(newValue) {
    let DECIMAL_ROUND_OF = 3
        
    if(!newValue.countDecimals()){
        DECIMAL_ROUND_OF = 0
    }

    if (newValue === '' || newValue === null || typeof newValue === 'undefined') {
      this.group.data.milkProduction = null // undefined;
    } else {
      this.group.data.milkProduction = parseFloat(convert(newValue)
        .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg'))
        .to('kg'))
        .toDecimal(newValue.countDecimals() + DECIMAL_ROUND_OF);
      console.log(this.group.data.milkProduction)
    }
  }

  getAssessors() {
    let assessorsList = [];
    if (this.group.assessors) {
      if (Array.isArray(this.group.assessors)) {
        return this.group.assessors.map(assessor => {
          return this.getAssessorByType(assessor.type);
        });
      } else {
        for (let assessorKey in this.group.assessors) {
          assessorsList.push(this.getAssessorByType(assessorKey))
        }
      }
    }
    return assessorsList;
  }

  getAssessorByType(type) {
    if (this.group.assessors) {
      let assessor;
      if (Array.isArray(this.group.assessors)) {
        assessor = this.group.assessors.find(assessor => {
          return assessor.type === type;
        });
      } else {
        for (let assessorId in this.group.assessors) {
          if (this.group.assessors[assessorId].type === type) {
            assessor = this.group.assessors[assessorId];
          }
        }
      }

      if (assessor === undefined) return false;
      if (type === "1") {
        return new HygieneAssessor(this.app, this.evaluation, this, assessor);
      } else if (type === "2") {
        return new FreeStall(this.app, this.evaluation, this, assessor);
      } else if (type === "3") {
        return new Locomotion(this.app, this.evaluation, this, assessor);
      } else if (type === "4") {
        return new TimeBudget(this.app, this.evaluation, this, assessor);
      } else if (type === "5") {
        return new DirtLot(this.app, this.evaluation, this, assessor);
      } else if (type === "5") {
        return new DirtLot(this.app, this.evaluation, this, assessor);
      } else if (type === "6") {
        return new Biosecurity(this.app, this.evaluation, this, assessor);
      } else if (type === "7") {
        return new BeddedPack(this.app, this.evaluation, this, assessor);
      } else if (type === "9") {
        return new Racetrack(this.app, this.evaluation, this, assessor);
      } else if (type === "10") {
        return new TieStallStanchion(this.app, this.evaluation, this, assessor);
      } else if (type === "11") {
        return new HoldingArea(this.app, this.evaluation, this, assessor);
      } else if (type === "12") {
        return new FreestallVentilation(this.app, this.evaluation, this, assessor);
      } else if (type === "13") {
        return new TieStallStanchionHeatAbatement(this.app, this.evaluation, this, assessor);
      } else if (type === "14") {
        return new NutritionAndFeeding(this.app, this.evaluation, this, assessor);
      } else if (type === "15") {
        return new HoofTrimming(this.app, this.evaluation, this, assessor);
      } else if (type === "16") {
        return new HeatAbatement(this.app, this.evaluation, this, assessor);
      } else if (type === "17") {
        return new Lesion(this.app, this.evaluation, this, assessor);
      } else if (type === "18") {
        return new WalkingSurface(this.app, this.evaluation, this, assessor);
      } else if (type === "19") {
        return new Transition(this.app, this.evaluation, this, assessor);
      } else if (type === "20") {
        return new HoldingYardMilkShed(this.app, this.evaluation, this, assessor);
      }
    }
    return null;
  }
}

export default Group;
